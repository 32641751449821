import React from 'react'
import Checkbox from '../Common/Checkbox'
import styled from 'styled-components';

function Ingredient(props) {
  const { ingredient,i,handleDone } = props;
  return (
    <Container key={ingredient.name}>
      <p>{ingredient.name}</p>
      <p>{ingredient.prepMethod}</p>
      <Checkbox unique={ingredient.name} checked={ingredient.done} onChange={() => handleDone(i)} />
    </Container>
  )
}

export default Ingredient

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    background-color: ${(props) => props.theme.secondary};
    border-radius: 10px;
    padding: 10px;
    > p {
      margin: 0;
    }
`