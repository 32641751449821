import React from 'react';
import styled from 'styled-components';
import { difficulties } from '../../../util/constants';

function TextInput(props) {
  const { onChange, label, field } = props;
  return (
    <label>
      {label}
      <select name="recipeDifficulty" id="" onChange={(e) => props.onChange(e)}>
        {difficulties.map((difficulty, i) => <option value={i}>{`${difficulty} (${i + 1}/5)`}</option>)}
      </select>
    </label>
  );
}

export default TextInput;
