import React,{ Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import media from 'styled-media-query';
import Ingredient from '../Ingredient/Ingredient';

export class Recipe extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   recipeDescription: "loading",
    //   recipeName: "loading",
    // }
    this.state = {
      recipeTitle: 'Randerz Salad',
      recipeDescription:
        'This is a dummy description is this. A medium sized bit of text about how the recipe is eaten or what food it goes with. Maybe ending with a hillarious line.',
      recipeAuthor: 'Finlay',
      ingredientsOrMethod: 'ingredients',
      recipeIngredients: [
        {
          name: 'Corriander',
          prepMethod: 'Chopped',
          done: false,
        },
        {
          name: 'Onion',
          prepMethod: 'Diced',
          done: false,
        },
        {
          name: 'Cucumber',
          prepMethod: 'Chopped',
          done: false,
        },
      ],
      recipeMethod: ['Put all the ingredients in a bowl.','Mix all the ingredints with a big old spoon.'],
    };
    this.toggleMethodAndRecipe = this.toggleMethodAndRecipe.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.isPrepComplete = this.isPrepComplete.bind(this);
  }

  toggleMethodAndRecipe() {
    this.setState({
      ingredientsOrMethod: 'method',
    });
  }

  handleDone(i) {
    const { recipeIngredients } = this.state;
    const newRecipeIngredients = [...recipeIngredients];

    newRecipeIngredients[i].done = true;
    this.setState({
      recipeIngredients: newRecipeIngredients,
    });
  }

  isPrepComplete() {
    console.log('is prep complete called');

    const { recipeIngredients } = this.state;
    let status = true;
    recipeIngredients.forEach((ingredient) => {
      if (!ingredient.done) {
        status = false;
      }
    });
    console.log({ status });

    return status;
  }

  // componentDidMount() {
  //   axios.get(`https://api.fantasyfoodfactory.com/recipes/${this.props.match.params.recipeSlug}`)
  //   .then((response) => {
  //     console.log(response);
  //     const { recipeName, recipeDescription } = response.data;
  //     this.setState({
  //       recipeName,
  //       recipeDescription,
  //     })
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }

  render() {
    const {
      recipeTitle,
      recipeAuthor,
      recipeDescription,
      ingredientsOrMethod,
      recipeIngredients,
      recipeMethod,
    } = this.state;

    return (
      <Container>
        <RecipeHeader>
          <div className="left-side">
            <Title>{recipeTitle}</Title>
            <Author>{`by ${recipeAuthor}`}</Author>
          </div>
          <div className="right-side">
            <Description>{recipeDescription}</Description>
          </div>
        </RecipeHeader>

        {ingredientsOrMethod === 'ingredients' && (
          <Ingredients>
            {recipeIngredients.map((ingredient,i) => (
              <Ingredient ingredient={ingredient} i={i} handleDone={this.handleDone} />
            ))}
          </Ingredients>
        )}

        {ingredientsOrMethod === 'method' && (
          <Method>
            {recipeMethod.map((step,i) => (
              <p>{`${i + 1}. - ${step}`}</p>
            ))}
          </Method>
        )}
        <ContinueSection>
          <p>All prepped?</p>
          <button disabled={!this.isPrepComplete()} type="button" onClick={this.toggleMethodAndRecipe}>
            Continue to recipe
          </button>
        </ContinueSection>
      </Container>
    );
  }
}

export default Recipe;

const Container = styled.section`
  min-height: 100vh;
  /* background-color: ${(props) => props.theme.primary}; */
`;

const RecipeHeader = styled.div`
  background-color: ${(props) => props.theme.primary};
  padding: 20px;
  color: white;
`

const Title = styled.h1`
  font-size: 2rem;
  padding-bottom: 5px;
`;

const Author = styled.h2`
  font-size: 1rem;
`;

const Description = styled.p`
  text-align: center;
`;

const Ingredients = styled.section`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr ;
  grid-gap: 10px;
  padding: 10px;

`;

const Method = styled.section``;

const ContinueSection = styled.section`
    margin-top: 20px;
    text-align: center;
  button {
          background-color: ${(props) => props.theme.primary};
      border: none;
      border-radius: 10px;
      padding: 20px;
    }
  `;
