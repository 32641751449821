const textTransformation = (state = {}, action) => {

  switch (action.type) {
    case "TO_CAPS":
    return {
      ...state,
      caps: action.caps,
      lowercase: action.lowercase,
    }
    default:
      return state
  }
}

export default textTransformation