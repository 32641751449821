import React,{ Component } from 'react';
import { BrowserRouter as Router,Route,Link } from 'react-router-dom';
// import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { ConsoleLogger } from '@aws-amplify/core';
import {
  registerUser,signIn,signOut,getAuthState,
} from '../util/auth';


import Menu from './Menu/Menu';
import MenuToggle from './Menu/MenuToggle';
import Recipe from './Recipe/Recipe';
import NewRecipe from './NewRecipe/NewRecipe';
import GlobalStyles from '../styles/globalStyles';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      user: null,
      isAuthenticated: false,
      checkedForUser: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  async componentDidMount() {
    // Check auth
    try {
      // const session = await Auth.currentSession();
      // const user = await Auth.currentAuthenticatedUser();
      const [session,user] = await Promise.all([
        Auth.currentSession(),
        Auth.currentAuthenticatedUser(),
      ]);

      console.log('user',user);
      console.log('session',session);
      const { email,name } = user.attributes;

      this.setState({
        checkedForUser: true,
        isAuthenticated: true,
        name,
        email,
        // session,
      });
    } catch (error) {
      this.setState({
        checkedForUser: true,
        isAuthenticated: false,
      });
      console.log(error);
    }
  }


  toggleMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  handleSignOut() {
    signOut()
      .then(async (data) => {
        const { name,email } = await getAuthState();
        this.setState();
        console.log('data',data);
      })
      .catch((err) => {
        console.log('err',err);
      });
  }

  handleFileChange(event,x) {
    console.log(event,x);
    console.log(this);
  }


  render() {
    const {
      name,email,checkedForUser,showMenu,
    } = this.state;


    return (
      <div className="App">
        <GlobalStyles />
        {showMenu
          && <Menu toggleMenu={this.toggleMenu} />}
        {!showMenu
          && <MenuToggle toggleMenu={this.toggleMenu} />}
        <Route path="/recipes/:recipeSlug" component={Recipe} />
        {/* <Two /> */}
        {/* <Three /> */}
        <p>{name}</p>
        <p>{email}</p>
        <button type="button" onClick={() => registerUser('finlaypercy+2@gmail.com','password','flinaly 2')}>REGISTER</button>
        <button type="button" onClick={() => signIn('finlaypercy+2@gmail.com','password')}>LOGIN</button>
        <button type="button" onClick={this.handleSignOut}>Signout</button>
        <NewRecipe />
        {/* <input type="file" id="input" onChange={(x) => this.handleFileChange(x)} /> */}
      </div>
    );
  }
}

export default App;
