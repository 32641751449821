import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Aleo', serif;
  }

  h1 {
    margin: 0;
    font-size: 3rem;
    padding: 0;
  }
  h2 {
    margin: 0;
    font-size: 2rem;
  }
  .handwriting {
    font-family: 'Great Vibes', cursive;
  }
`
export default GlobalStyle;