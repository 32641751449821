import React, { Component } from 'react'
import styled from 'styled-components';
import media from 'styled-media-query';
import colours from '../../util/colours'

export class Menu extends Component {
  render() {
    return (
      <Container colours={colours}>
        <h2 onClick={this.props.toggleMenu}>Menu</h2>
      </Container>
    )
  }
}

export default Menu

const Container = styled.nav`
  position: fixed;
  max-width: 200px;
  width: 100%;
  background-color: ${(props) => props.colours.primary};
  margin-top: 20px;
  border: 2px solid ${(props) => props.colours.secondary};
  border-left: none;
  /* background-color: ${(props) => props.colours.menu}; */
  h2 {
    color: ${(props) => props.colours.secondary};
    text-align: center;
    padding: 10px 0;
    font-size: 1rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${media.lessThan('medium')`
    max-width: 70px;
  `}

`;