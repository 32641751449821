import axios from 'axios';

const recipe = {
  hello: 'hello',
  bla: 'bla',
};

export const uploadRecipe = (payload) => dispatch => {
  dispatch({
    type: 'START_UPLOAD',
    payload: 'blabber',
  });

  axios.post(`${process.env.REACT_APP_API_URL}/recipes/`, recipe).then(() => {
    dispatch({
      type: 'FINISH_UPLOAD',
      payload: 'DONE',
    });
  }).catch((error) => {
    dispatch({
      type: 'FAILED_UPLOAD',
      payload: error,
    });
  });


  // setTimeout(
  //   () => dispatch({
  //     type: 'FINISH_UPLOAD',
  //     payload: 'DONE',
  //   }), 3000,
  // );
};
