const axios = require('axios');

// Make a request for a user with a given ID

export const uploadImageToS3 = async (imageName, file) => {
  console.log(file);
  const imageNameWithoutSpace = imageName.replace(/\s/g, '_');
  console.log(imageNameWithoutSpace);
  try {
    const { data: imageUrl } = await axios.post(`http://localhost:3000/images/${imageNameWithoutSpace}`, file);
    return imageUrl;
  } catch (error) {
    return error;
  }
};
