import React from 'react';
import styled from 'styled-components';

function TextInput(props) {
  const { onChange, label, field } = props;
  return (
    <label>
      {label}
      <textarea name={field} type="text" onChange={(e) => onChange(e)} />
    </label>
  );
}

export default TextInput;
