const defaultObject = {
  save: 'beginning text',
};

const saveData = (state = defaultObject, action) => {
  console.log('====================================');
  console.log(action);
  console.log('====================================');
  switch (action.type) {
    case 'SAVE':
      return {
        ...state,
        save: action.text,
      };
    case 'UPLOAD_RECIPE':
      return {
        ...state,
        save: action.text,
      };

    default:
      return state;
  }
};

export default saveData;
