import React, { Component } from 'react';
import { units, prepMethods } from '../../../util/constants';

class StringArrayInput extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.addStep = this.addStep.bind(this);
    this.removeStep = this.removeStep.bind(this);
  }

  handleChange(e, i) {
    const { name, value } = e.target;
    const { onChange, field, value: newValue } = this.props;
    const newArray = newValue.splice(0);

    newArray[i][name] = value;
    onChange({
      target: {
        name: field,
        value: newArray,
      },
    });
  }

  addStep(i) {
    const { onChange, value, field } = this.props;
    const newArray = value.splice(0);
    const emptyIngredient = {
      amount: '',
      unit: 'Whole',
      name: '',
      prepMethod: '',
    };
    newArray.splice(i + 1, 0, emptyIngredient);
    onChange({
      target: {
        name: field,
        value: newArray,
      },
    });
  }

  removeStep(i) {
    const { field, value, onChange } = this.props;
    const newArray = value.splice(0);
    newArray.splice(i, 1);
    onChange({
      target: {
        name: field,
        value: newArray,
      },
    });
  }

  render() {
    const { value, label } = this.props;
    return (
      <div>
        <label>
          {label}
          {value.map((ingredient, i) => (
            <div key={i}>
              <input placeholder="500 / 1 / 0.5" name="amount" type="number" value={ingredient.amount} onChange={(e) => this.handleChange(e, i)} />
              <select name="unit" id="" onChange={(e) => this.handleChange(e, i)}>
                {units.map((unit) => <option key={unit} value={unit}>{unit}</option>)}
              </select>
              <input placeholder="potato / onion / gravy" name="name" type="text" value={ingredient.name} onChange={(e) => this.handleChange(e, i)} />
              <select name="prepMethod" id="" onChange={(e) => this.handleChange(e, i)}>
                {prepMethods.map((prepMethod) => <option value={prepMethod}>{prepMethod}</option>)}
              </select>
              <button type="button" onClick={() => this.removeStep(i)}>-</button>
              <button type="button" onClick={() => this.addStep(i)}>+</button>
            </div>
          ))}
          <button type="button" onClick={() => this.addStep(value.length + 1)}>Add step</button>
        </label>
      </div>
    );
  }
}

export default StringArrayInput;
