export const prepMethods = [
  'Whole',
  'Chopped',
  'Diced',
  'Minced',
  'Peeled',
  'Julienne',
  'Halved',
  'Quartered',
  'Cut into chunks',
  'As it is',
];

export const units = [
  '',
  'g',
  'kg',
  'ml',
  'cl',
  'l',
  'little spoon',
  'big spoon',
  'sploosh',
  'Sprinkly boi',
];

export const difficulties = [
  'Tinchy Stryder',
  'Easy',
  'Baverage',
  'Sweaty boy',
  'Super difficult deluxe supreme',
];
