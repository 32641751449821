import { Storage } from 'aws-amplify';

export const s3Upload = async (file) => {
  const filename = `${Date.now()}-${file.name}`;
  console.log('filename', filename);
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  console.log('stored', stored);

  return stored.key;
};
