import React, { Component } from 'react';
import { connect } from 'react-redux';
import { s3Upload } from '../../util/storage';

import TextInput from '../Common/Inputs/TextInput';
import TextAreaInput from '../Common/Inputs/TextAreaInput';
import StringArrayInput from '../Common/Inputs/StringArrayInput';
import IngredientsInput from '../Common/Inputs/IngredientsInput';
import DifficultyInput from '../Common/Inputs/DifficultyInput';
import { uploadRecipe } from '../../actions/recipes';

import { uploadImageToS3 } from '../../util/api-routes';

// - recipeTitle (string)
// - recipeDescription (string)
// - recipeAuthor (string)
// - recipeIngredients [objects]
// - recipeMethod [strings]
// - recipeImgUrl (string)
// - recipeDifficulty (string)

export class NewRecipe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipeTitle: '',
      recipeImage: '',
      recipeAuthor: '',
      recipeMethod: ['Boil the spuds'],
      recipeIngredients: [
        {
          amount: '',
          unit: 'whole',
          name: '',
          prepMethod: '',
        },
      ],
      recipeDifficulty: 1,
      submitting: false,
    };

    this.fileInput = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.testing = this.testing.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitting: true,
    });
    const { file } = this.state;
    if (file) {
      const key = await s3Upload(file);
      console.log('key', key);
    }
  }

  async handleFileChange(e) {
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    console.log(file);
    console.log(file.name);
    // const imageUrl = await uploadImageToS3(file.name, file);
    const reader = new FileReader();
    reader.onload = async (ev) => {
      const recipeImage = ev.target.result;
      const imageUrl = await uploadImageToS3(file.name, recipeImage);
      this.setState({
        recipeImage,
        file,
        imageUrl,
      });
    };
    await reader.readAsDataURL(file);
  }

  testing() {
    // eslint ignore-next-line
    this.props.uploadRecipe();
  }

  handleChange(e) {
    const { name, value } = e.target;
    console.log('handle then chagne');

    console.log(name, value);
    if (!name || !value) {
      return false;
    }
    this.setState({
      [name]: value,
    });
    return 'fuck you';
  }

  render() {
    const {
      recipeImage, recipeTitle, recipeDescription, recipeAuthor, recipeMethod, recipeIngredients, recipeDifficulty, submitting,
    } = this.state;

    return (
      <div>
        <button type="button" onClick={this.testing}>uploadRecipeTESTING</button>
        <form onSubmit={this.handleSubmit}>
          <br />
          <br />
          <TextInput value={recipeTitle} field="recipeTitle" label="The name of the recipe" onChange={this.handleChange} />
          <br />
          <br />
          <TextAreaInput value={recipeDescription} field="recipeDescription" label="The description of the recipe" onChange={this.handleChange} />
          <br />
          <br />
          <TextInput value={recipeAuthor} field="recipeAuthor" label="Author" onChange={this.handleChange} />
          <br />
          <br />
          <StringArrayInput value={recipeMethod} field="recipeMethod" label="Recipe method" onChange={this.handleChange} />
          <br />
          <br />
          <IngredientsInput value={recipeIngredients} field="recipeIngredients" label="Recipe ingrdients" onChange={this.handleChange} />
          <br />
          <br />
          <DifficultyInput value={recipeDifficulty} onChange={this.handleChange} label="Recipe difficulty" />
          <br />
          <br />
          <label htmlFor="image">
          Tasty image fo the food:
            <input id="image" type="file" ref={this.fileInput} onChange={this.handleFileChange} />
          </label>
          <img src={recipeImage} alt="" />
          <br />
          <button disbaled={submitting} type="submit">Submit</button>
          <br />
          <br />
          {submitting && <h2>Submitting...Hold tight</h2>}
        </form>
      </div>
    );
  }
}

const MapStateToProps = () => {
};

export default connect(
  MapStateToProps,
  { uploadRecipe },
)(NewRecipe);
