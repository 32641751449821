import React from 'react'
import css from '../../styles/checkbox.css'
import styled from 'styled-components';


const Checkbox = (props) => {
  return (
    <Container style={{ position: "relative" }} onClick={props.onChange}>
      <Circle></Circle>
      <input checked={props.checked} type="checkbox" className={'funkyCheckbox'} id={props.unique} style={{ display: "none" }} type="checkbox" />
      <label htmlFor={props.unique} class="check">
        <svg width="24px" height="24px" viewBox="0 0 18 18">
          <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
          <polyline points="1 9 7 14 15 4"></polyline>
        </svg>
      </label>
    </Container>
  )
}

export default Checkbox

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Circle = styled.div`
  position: absolute;
  background-color: rgba(255,255, 255,0.3);
  z-index: 0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  top: -13px;
  left: -13px;
`