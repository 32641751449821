import { combineReducers } from 'redux';
import saving from './saving';
import textTransformation from './textTransformation';
import websiteContent from './websiteContent';

export default combineReducers({
  saving,
  textTransformation,
  websiteContent,
});
