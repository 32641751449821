// import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

// export const checkAuth = () => {
//   const data = {
//     UserPoolId: 'eu-west-2_j0RXRQFpT',
//     ClientId: '6qqeka0i6ge4rg3aactmcobi3q',
//   };
//   const userPool = new CognitoUserPool(data);
//   const cognitoUser = userPool.getCurrentUser();

//   if (cognitoUser != null) {
//     cognitoUser.getSession((err, session) => {
//       if (err) {
//         alert(err);
//         return;
//       }
//       console.log(`session validity: ${session.isValid()}`);
//     });
//   }
// };

export const registerUser = async (email, password, name) => {
  try {
    const signUpResponse = await Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
      },
    });
    console.log(signUpResponse);
    console.log('route them');
  } catch (error) {
    console.log(error);
  }
};

export const signIn = async (email, password) => {
  try {
    const user = await Auth.signIn({
      username: email,
      password,
    });
    console.log(user);
    return user;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signOut = () => new Promise((resolve, reject) => {
  Auth.signOut()
    .then((data) => resolve(data))
    .catch((error) => reject(error));
});

export const getAuthState = async () => {
  try {
    const [session, user] = await Promise.all([
      Auth.currentSession(),
      Auth.currentAuthenticatedUser(),
    ]);

    const { email, name } = user.attributes;

    return {
      name,
      email,
    };
  } catch (error) {
    console.log(error);
    return {
      name: 'loggedOut',
      email: 'loggedOut',
    };
  }
};
