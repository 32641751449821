const defaultWebsiteContent = {
  one_title: 'ONE',
}

const websiteContent = (state = defaultWebsiteContent, action) => {
  switch (action.type) {
    case 'UPDATE_CONTENT':
    return {
      ...state,
      [action.field]: action.value,
    }

    default:
      return state
  }
}

export default websiteContent