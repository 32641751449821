import React, { Component } from 'react'
import styled from 'styled-components';
import colours from '../../util/colours'
import {Link} from 'react-router-dom'

export class Menu extends Component {
  render() {
    return (
      <Container colours={colours}>
      <div>
        <h2 className="handwriting">Finlay's</h2>
        <ul>
          <Link className='inheritColour' to="/"><li>Home</li></Link>
          <Link className='inheritColour' to="/recipes"><li>Recipes</li></Link>
          <Link className='inheritColour' to="/recipes/asdf"><li>Recipe asdf</li></Link>
          <Link className='inheritColour' to="/team"><li>Meet the team</li></Link>
          <Link className='inheritColour' to="/team/finlay"><li>Single team</li></Link>
        </ul>
      </div>
        <h2 className="closeButton" onClick={this.props.toggleMenu}>Close</h2>
      </Container>
    )
  }
}

export default Menu

const Container = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.colours.primary};
  background-color: ${(props) => props.colours.menu};
  .closeButton {
    padding-bottom: 30px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: ${(props) => props.colours.secondary };
    }
  }
  h2 {
    text-align: center;
    margin-top: 30px;
  }
  ul {
    list-style-type: none;
    font-size: 3vmin;
    padding: 0;
    .inheritColour {
      color: inherit !important;
      text-decoration: inherit !important;
    }
    li {
      text-align: center;
      padding: 20px;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: ${(props) => props.colours.secondary };
      }
      &:visited {
        color: inherit;
      }
    }
  }
`;